import '@/assets/scss/main.css'
import router from './router'
import App from './App.vue'
import WebApp from '@twa-dev/sdk'
import 'virtual:svg-icons-register'

declare global {
  interface Window {
    WebApp?: typeof WebApp // 使用可选属性以允许其为 undefined 或存在
  }
}

// 安全地设置 window.WebApp
if (typeof window !== 'undefined') {
  window.WebApp = WebApp
}

WebApp.ready()
WebApp.expand()
WebApp.isVerticalSwipesEnabled && WebApp.disableVerticalSwipes()
WebApp.setHeaderColor('#0F1012')
WebApp.setBackgroundColor('#0F1012')

WebApp.onEvent('themeChanged', function () {
  document.body.setAttribute('style', '--bg-color:' + WebApp.backgroundColor)
})

const token = new URLSearchParams(location.search).get('token')

if (token) {
  localStorage.setItem('token', token)
}

createApp(App).use(router).mount('#app')

// 阻止移动端缩放
if (window.innerWidth < 768) {
  document.addEventListener('gesturestart', function (event) {
    event.preventDefault()
  })
}

//ios
document.oncontextmenu = function (e) {
  e.preventDefault()
}
document.onselectstart = function (e) {
  e.preventDefault()
}
//安卓
document.addEventListener('contextmenu', function (e) {
  e.preventDefault()
})
document.ontouchend = function () {
  // throw new Error('NO ERRPR:禁止长按弹出')
}
