import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_APP_BASE_URL),
  routes: [
    {
      path: '/',
      component: () => import('@/layout/index.vue'),
      redirect: '/tap',
      children: [
        {
          path: '/earn',
          name: 'Earn',
          component: () => import('@/views/Earn.vue')
        },
        {
          path: '/tap',
          name: 'Tap',
          component: () => import('@/views/Tap.vue')
        },
        {
          path: '/claim',
          name: 'Claim',
          component: () => import('@/views/Claim.vue')
        }
      ]
    },
    // TODO:不需要layout的页面
    {
      path: '/:pathMatch(.*)*',
      redirect: '/'
    }
  ]
})

export default router
